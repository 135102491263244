:root {
  --primary-color: #10a37f;
  --secondary-color: #1a7f64;
  --third-color: #3dbe9b;
  --primary-color-light: #e6f7f4;
  --background-color: #ffffff;
  --text-color: #2c3e50;
  --sidebar-bg: #202123;
  --chat-bg: #f7f7f8;
  --bot-msg-bg: #f7f7f8;
  --user-msg-bg: #10a37f;
}
[data-bs-theme="dark"] {
  --background-color: #343541;
  --text-color: #ffffff;
  --chat-bg: #444654;
  --bot-msg-bg: #444654;
  --user-msg-bg: #10a37f;
}
body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  overflow-y: hidden;
}

a {
  /* color: var(--primary-color); */
  text-decoration: none;
  cursor: pointer;
}

.sidebar {
  background-color: var(--sidebar-bg);
  color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.sidebar-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px 0;
}
.sidebar .nav-link,
.offcanvas-body .nav-link {
  color: white;
  padding: 10px 20px;
}
.sidebar .nav-link:hover,
.offcanvas-body .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.main-content {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.navbar {
  background-color: var(--background-color);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.chat-wrapper {
  max-width: 1024px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.content-wrapper {
  /* max-width: 1024px; */
  /* margin: 0 auto; */
  padding: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.chat-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* overflow-y: auto; */
  padding: 30px;
}
.chat-input-container {
  padding: 20px;
  background-color: var(--background-color);

  /* border-top: 1px solid rgba(0, 0, 0, 0.1); */
}

/* .chat-input-container > .input-group {
  width: 100%;
  z-index: 99;
} */

.chat-input {
  border-radius: 20px;
  /* border: 1px solid var(--primary-color); */
  background-color: #f7f7f8;
  padding: 10px 20px;
}
.send-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  /* height: 40px; */
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.message-wrapper {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.message {
  max-width: 100%;
  /* width는 컨텐츠 사이즈 */
  width: fit-content;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.message-text p {
  margin-bottom: 0;
}

.message.bot {
  align-self: flex-start;
  background-color: var(--bot-msg-bg);
  color: var(--text-color);
}
.message.user {
  align-self: flex-end;
  background-color: var(--user-msg-bg);
  color: white;
  border-radius: 18px;
}
.offcanvas {
  background-color: var(--sidebar-bg);
  color: white;
}
.offcanvas-header .btn-close {
  filter: invert(1);
}
.user-profile {
  padding: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
}
.user-profile img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.user-profile-info {
  display: flex;
  align-items: center;
}
.user-profile-text {
  display: flex;
  flex-direction: column;
}
.user-name {
  font-weight: bold;
}
.user-email {
  font-size: 0.8em;
  opacity: 0.8;
}

/* .chat-input {
  border-radius: 25px;
  padding-right: 50px;
}
.btn-send {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #28a745;
} */

.nav-link.active {
  /* // background-color: var(--primary-color); */
  /* // primary-color, opacity 0.1 */
  /* background-color: rgba(16, 163, 127, 0.5); */
  background-color: var(--primary-color);
}

.nav-item:hover {
  background-color: rgba(16, 163, 127, 0.5);
}

.nav-item {
  cursor: pointer;
}

.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-primary:hover {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.pagination > .page-item > .page-link {
  background-color: var(--third-color);
  border-color: var(--primary-color);
  color: white;
}

/* accordion open panel background */
.accordion-button:not(.collapsed) {
  background-color: var(--primary-color-light);
  /* color: white; */
}

.formatted-text {
  white-space: pre-wrap;
}

.page-link {
  cursor: pointer;
}

.page-link.active {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.container.login {
  /* 배경 이미지 assets/images/bg/bg01.jpg */
  background-image: url("../public/assets/images/bg/bg01.jpg");
  /* no repeat */
  background-size: cover;
}

.container.login .card {
  background-color: rgba(255, 255, 255, 0.6);
}

.kakao-login-button {
  width: 100%;
  max-width: 300px;
  cursor: pointer;
}

.link-modal {
  cursor: pointer;
  color: var(--primary-color);
  font-weight: 600;
}

@media (max-width: 767.98px) {
  .main-content {
    height: calc(100vh - 56px);
  }
}
